import React, { useRef, useEffect, useState, useContext } from 'react';
import { Box, useTheme } from '@mui/material';
import { CSVLink } from 'react-csv';
import { ReactComponent as Download } from 'assets/download.svg';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
/* eslint-disable camelcase */
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { formatDate, formatDateTime } from 'utils/dates';
import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ToastDataContext, AuthDataContext, RegionDataContext} from 'contexts';
import { Loading, FilterDropdown, DateRangeDropdown } from 'components';
import Cookies from 'universal-cookie';
import { cloneDeep } from 'lodash';
import WidgetHeader from './WidgetHeader';
import Widget from './Widget';

dayjs.extend(utc);
dayjs.extend(timezone);

am5.addLicense('AM5C329389658');

const BuyBoxWinRateChart = ({ asinCount }) => {
  const seriesRef = useRef(null);
  const actionSeriesRef = useRef(null);
  const xAxisRef = useRef(null);
  const { setToast } = useContext(ToastDataContext);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [exportFilename, setExportFilename] = useState('');
  const [exportData, setExportData] = useState('');
  const [seller, setSeller] = useState('_a_');
  const [sellerList, setSellerList] = useState([
    { id: '_a_', name: 'Authorized' },
    { id: '_na_', name: 'Not Authorized' },
  ]);
  const [allSellers, setAllSellers] = useState([]);
  const theme = useTheme();
  const cookies = new Cookies();
  const { account } = useContext(AuthDataContext);
  const { platform, regionIso, platformTerms } = useContext(RegionDataContext);
  const cookieName = `BuyBoxWinRateChartSetting.${account.id}`;
  const initialCookieName = `InitialBuyBoxWinRateChartSetting.${account.id}`;

  const dateRanges = (() => {
    const date = new Date();
    return [
      {
        label: 'Yesterday',
        startDate: dayjs(date).add(-1, 'day').startOf('day').toDate(),
        endDate: dayjs(date).add(-1, 'day').endOf('day').toDate(),
      },
      {
        label: 'Last Week',
        startDate: dayjs(date).add(-1, 'week').startOf('week').toDate(),
        endDate: dayjs(date).add(-1, 'week').endOf('week').toDate(),
      },
      {
        label: 'This Month',
        startDate: dayjs(date).startOf('month').toDate(),
        endDate: dayjs(date).endOf('month').toDate(),
      },
      {
        label: 'Last Month',
        startDate: dayjs(date).add(-1, 'month').startOf('month').toDate(),
        endDate: dayjs(date).add(-1, 'month').endOf('month').toDate(),
      },
      {
        label: 'Last 7 Days',
        startDate: dayjs(date).add(-6, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 30 Days',
        startDate: dayjs(date).add(-29, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 90 Days',
        startDate: dayjs(date).add(-89, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 180 Days',
        startDate: dayjs(date).add(-179, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
    ];
  })();

  const [dateRange, setDateRange] = useState(dateRanges[5]);

  const updateExport = () => {
    if (chartData) {
      const csvData = [['Date', 'Win Rate', 'Actions Taken']];
      for (let i = 0; i < chartData.length; i += 1) {
        const item = chartData[i];
        csvData.push([formatDateTime(new Date(item.epoch)), `${item.value}%`, item.actions]);
      }
      setExportData(csvData);
    }
  };

  useEffect(() => {
    const root = am5.Root.new('winratechart');

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {}));
    chart.zoomOutButton.set('forceHidden', true);

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        min: -1,
        strictMinMax: true,
        extraMax: 0.1,
        numberFormat: "#'%'",
      }),
    );

    // Create actionsTaken Y-axis
    const yAxisActions = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, { opposite: true }),
        min: 0,
        strictMinMax: false,
        extraMax: 0.25,
        numberFormat: '#',
      }),
    );

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        baseInterval: { timeUnit: 'day', count: 1 },
        tooltipDateFormat: 'MM, dd, yyyy',
        startLocation: -0.5,
        endLocation: 1.5,
        minGridDistance: 3,
      }),
    );

    // removes xaxis grid vertical lines
    const xRenderer = xAxis.get('renderer');
    xRenderer.grid.template.setAll({
      stroke: false,
    });

    // changes colors of xaxis label
    xRenderer.labels.template.setAll({
      fill: theme.palette.greys.grey,
      fontSize: '12px',
      fontFamily: theme.typography.fontFamily,
    });

    // makes the yaxis into dots
    const yRenderer = yAxis.get('renderer');
    yRenderer.grid.template.setAll({
      stroke: '#9EA7AB',
      strokeDasharray: [1, 4],
      strokeOpacity: 1,
    });

    // changes colors of yaxis label
    yRenderer.labels.template.setAll({
      fill: theme.palette.greys.grey,
      fontSize: '12px',
      fontFamily: theme.typography.fontFamily,
    });

    // don't show the actions grid
    const yRendererActions = yAxisActions.get('renderer');
    yRendererActions.grid.template.setAll({
      strokeOpacity: 0,
    });

    // changes colors of yaxis label
    yRendererActions.labels.template.setAll({
      fill: theme.palette.greys.grey,
      fontSize: '12px',
      fontFamily: theme.typography.fontFamily,
    });

    const actionSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Actions Taken',
        xAxis,
        yAxis: yAxisActions,
        valueYField: 'actions',
        valueXField: 'chartDate',
        fill: '#FF9061',
        fillOpacity: 0.5,
        strokeWidth: 0.0,
      }),
    );

    // Create series
    const series = chart.series.push(
      am5xy.SmoothedXYLineSeries.new(root, {
        name: 'Lost Sales',
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'chartDate',
        stroke: '#55ADFE',
        strokeOpacity: 1,
        tension: 0.8,
        minBulletDistance: 80,
        tooltip: am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          getLabelFillFromSprite: false,
          strokeWidth: 2,
          labelText: 'Win Rate: {value}%\nActions Taken: {actions}\nDate: {date}',
          pointerOrientation: 'horizontal',
        }),
      }),
    );

    actionSeries.columns.template.setAll({
      opacity: 0.5,
      width: am5.percent(80),
    });

    series
      .get('tooltip')
      .get('background')
      .setAll({
        fill: 'white',
        opacity: 0.9,
        shadowColor: am5.color(theme.palette.greys.silver),
        shadowOffsetY: 4,
        shadowOffsetX: 4,
        shadowBlur: 10,
      });

    series.get('tooltip').label.setAll({
      fill: 'black',
      fontSize: '12px',
    });

    series.data.processor = am5.DataProcessor.new(root, {
      numericFields: ['value'],
      dateFields: ['chartDate'],
      //   dateFormat: 'MM-DD-YYYY',
    });

    series.strokes.template.setAll({
      strokeWidth: 2,
    });

    // Add cursor
    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineX.setAll({
      stroke: am5.color(theme.palette.greys.silver),
    });

    cursor.lineY.setAll({
      stroke: am5.color(theme.palette.greys.silver),
    });

    series.bullets.push(() =>
      am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 4,
          fill: '#55ADFE',
        }),
      }),
    );

    xAxisRef.current = xAxis;
    seriesRef.current = series;
    actionSeriesRef.current = actionSeries;

    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    xAxisRef.current.data.setAll(chartData);
    seriesRef.current.data.setAll(chartData);
    actionSeriesRef.current.data.setAll(chartData);
    updateExport();
  }, [chartData]);

  const GET_DATA_QUERY = gql`
    query GetData(
      $authorized: Boolean
      $sellerId: String
      $dateStart: DateTime
      $dateEnd: DateTime
    ) {
      getWinRateHistory(
        authorized: $authorized
        sellerId: $sellerId
        dateStart: $dateStart
        dateEnd: $dateEnd
      ) {
        score
        date
        actionsTaken
      }
    }
  `;

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const modifiedData = dataToProcess.getWinRateHistory.map((d) => ({
        epoch: new Date(d.date),
        date: dayjs(new Date(d.date)).format('MM/DD/YYYY'),
        chartDate: new Date(d.date),
        value: `${Math.round(d.score * 100)}%`,
        actions: d.actionsTaken || 0,
      }));
      let filtered = allSellers;
      filtered = filtered.filter((a) => a.authorized);
      filtered.unshift({ id: '_na_', name: 'Not Authorized' });
      filtered.unshift({ id: '_a_', name: 'Authorized' });
      setSellerList(filtered);
      if (xAxisRef && seriesRef) {
        setChartData(modifiedData);
      }
      setLoading(false);
      setInitialLoad(false);
    }
  };

  const [fetchData] = useLazyQuery(GET_DATA_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const getAuthorizedStatus = (s) => {
    if (s === '_a_') {
      return true;
    }
    if (s === '_na_') {
      return false;
    }
    return null;
  };

  const getSellerId = (s) => {
    if (s === '_a_') {
      return null;
    }
    if (s === '_na_') {
      return null;
    }
    return s;
  };

  const processSellersData = (dataToProcess) => {
    if (dataToProcess) {
      const list = dataToProcess.getSellersSimple.map((o) => ({
        id: o.id,
        name: o.name,
        authorized: o.authorized,
      }));
      const unique = list.filter((e, i, a) => a.findIndex((t) => t.id === e.id) === i);
      const sorted = unique.sort((a, b) => a.name.localeCompare(b.name));
      const all = cloneDeep(sorted);
      setAllSellers(all);
      sorted.unshift({ id: '_na_', name: 'Not Authorized' });
      sorted.unshift({ id: '_a_', name: 'Authorized' });
      let sellerIdToUse = seller;
      if (cookies.get(cookieName)) {
        const id = cookies.get(cookieName);
        sellerIdToUse = id;
        let found = false;
        for (let i = 0; i < sorted.length; i += 1) {
          if (sorted[i].id === id) {
            found = true;
            break;
          }
        }
        if (!found) {
          setSeller('_a_');
          cookies.set(cookieName, '_a_');
          sellerIdToUse = '_a_';
        }
      }

      const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dateStart =  dayjs(dayjs(dateRange.startDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').startOf('day').toISOString();
      const dateEnd = dayjs(dayjs(dateRange.endDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').endOf('day').toISOString();

      fetchData({
        variables: {
          authorized: getAuthorizedStatus(sellerIdToUse),
          dateStart,
          dateEnd,
          sellerId: getSellerId(sellerIdToUse),
        },
      });
    }
  };

  const GET_SELLERS_QUERY = gql`
    query GetSellers {
      getSellersSimple {
        id
        name
        authorized
      }
    }
  `;

  const [fetchSellers] = useLazyQuery(GET_SELLERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processSellersData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (account) {
      if (!cookies.get(initialCookieName)) {
        cookies.set(initialCookieName, 'done');
        cookies.set(cookieName, '_a_');
      }
      setSeller(cookies.get(cookieName));
      fetchSellers();
    }
  }, [asinCount, account]);

  const handleChooseSeller = (s) => {
    setLoading(true);
    setSeller(s);
    cookies.set(cookieName, s);

    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateStart = dayjs(dayjs(dateRange.startDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').startOf('day').toISOString();
    const dateEnd = dayjs(dayjs(dateRange.endDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').endOf('day').toISOString();

    fetchData({
      variables: {
        authorized: getAuthorizedStatus(s),
        dateStart,
        dateEnd,
        sellerId: getSellerId(s),
      },
    });
  };

  const handleChooseRange = (r) => {
    setDateRange(r);
    setLoading(true);

    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateStart = dayjs(dayjs(dateRange.startDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').startOf('day').toISOString();
    const dateEnd = dayjs(dayjs(dateRange.endDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').endOf('day').toISOString();

    fetchData({
      variables: {
        authorized: getAuthorizedStatus(seller),
        dateStart,
        dateEnd,
        sellerId: getSellerId(seller),
      },
    });
  };

  const updateExportFilename = () => {
    let filename = `${platform}-${regionIso}-BuyBoxWinRateHistory`;
    if (seller === null) {
      filename = `${filename}-all`;
    } else if (seller === '_a_') {
      filename = `${filename}-Authorized`;
    } else if (seller === '_na_') {
      filename = `${filename}-NotAuthorized`;
    } else {
      filename = `${filename}-${seller}`;
    }
    const now = formatDate(new Date());
    filename = `${filename}-${now}`;
    setExportFilename(filename);
  };

  useEffect(() => {
    updateExportFilename();
  }, [seller]);

  return (
    <Widget>
      <WidgetHeader
        sx={{ pb: 0, minHeight: '66px' }}
        title="Win Rate History"
        tip={(
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box>
              For <i>Authorized</i>: The percentage of the time authorized sellers have won the
              buy box across all {platformTerms.productTermPlural} that are not suppressed.
            </Box>
            <Box>
              For <i>Not Authorized</i>: The percentage of the time sellers that are not
              authorized have won the buy box across all {platformTerms.productTermPlural} that are not
              suppressed.
            </Box>
            <Box>
              For <i>Individual Seller</i>: The percentage of the time the individual seller has
              won the buy box for attached {platformTerms.productTermPlural} that are not suppressed.
            </Box>
          </Box>
        )}
        right={
          <CSVLink target="_blank" filename={exportFilename} data={exportData}>
            <Download />
          </CSVLink>
        }
        bottom={
          <Box>
            {!initialLoad && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <FilterDropdown
                  disableAllOption
                  label="Seller"
                  items={sellerList?.map((s) => ({
                    value: s.id,
                    title: s.name,
                  }))}
                  value={seller}
                  datacy="select_dropdown_option_Seller"
                  onSelect={handleChooseSeller}
                  truncateLength={25}
                />
                <DateRangeDropdown
                  ranges={dateRanges}
                  initialRange={dateRange}
                  onChooseRange={handleChooseRange}
                />
              </Box>
            )}
          </Box>
        }
      />
      <Box>
        <Box sx={{ display: 'grid' }}>
          {loading && (
            <Box
              sx={{
                gridRowStart: 1,
                gridColumnStart: 1,
                height: '225px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loading />
            </Box>
          )}
          <Box
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              height: '230px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              opacity: loading ? '0.1' : '1.0',
            }}
          >
            <Box
              id={'winratechart'}
              sx={{
                pt: 0,
                px: 0.5,
                mb: 0,
                width: '97%',
                height: '235px',
                display: 'inline-block',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Widget>
  );
};

export default BuyBoxWinRateChart;
