import React, { Suspense, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Drawer,
  Tab,
  Box,
  useTheme,
} from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { Loading, Scrollable } from 'components';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { gql, useQuery } from '@apollo/client';
import Tiers from 'pages/Clients/Tiers/Tiers';
import ClientProfile from './ClientProfile';
import Users from './Users';
import TestBuyCredits from './Credits/TestBuyCredits';

const wideDrawerWidth = 'calc(100vw - 194px)';

const ClientDrawer = ({ open, onClose, id, tenantId, onUpdated }) => {
  const [client, setClient] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [previousOpen, setPreviousOpen] = useState();
  const [tab, setTab] = useState('1');
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const { user, hasPermission } = useContext(AuthDataContext);
  const theme = useTheme();

  const GET_CLIENT_QUERY = gql`
    query GetClient($id: ID!, $tenantId: ID!) {
      getAccount(id: $id) {
        id
        name
        asinCount
        fetchInventory
        logo
        demo
        highlyRestricted
        externalEnforcementRoutingEmail
        users {
          id
          userId
          createdDate
          lastLoggedIn
          firstName
          lastName
          email
        }
        configuredFeatures {
          id 
          name
          description
        }
        permittedFeatures {
          featureName
          regionId
        }
        configuredRegions {
          id
          iso
          name
          nativeName
          platform
        }
      }
      getTenant(id: $tenantId) {
        id
        navLogo
        navColor
        tenant
        configuredFeatures {
          id
          name
          description
        }
        configuredRegions {
          id
          iso
          name
          nativeName
          platform
        }
      }
      getSubtenants(id: $tenantId) {
        id
        disabled
      }
    }
  `;

  const { data } = useQuery(GET_CLIENT_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { id, tenantId },
    skip: !id || !tenantId,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const processData = (dataToProcess) => {
    if (dataToProcess.getAccount && dataToProcess.getSubtenants) {
      setClient(dataToProcess.getAccount);
      setTenant(dataToProcess.getTenant);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  if (open !== previousOpen) {
    setIsOpen(open);
    setPreviousOpen(open);
    setLoading(true);
    setTab('1');
  }

  const headerContent = (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        fontSize: '14px',
        fontWeight: '400',
        color: 'greys.silver',
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <Box>
        <b>Edit Client</b>
      </Box>
    </Box>
  );

  return (
    <Drawer
      data-cy="seller_insights_drawer"
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{
        width: wideDrawerWidth,
        flexShrink: 0,
        borderTopLeftRadius: theme.borderRadius,
        borderBottomLeftRadius: theme.borderRadius,
        '& .MuiDrawer-paper': {
          width: wideDrawerWidth,
          bgcolor: 'greys.white',
          overflow: 'hidden',
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
        },
      }}
    >
      <TabContext value={tab}>
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box
            sx={{
              bgcolor: 'greys.backgroundGrey',
              display: 'flex',
              justifyContent: 'space-between',
              px: 2,
              maxHeight: '44px',
              minHeight: '44px',
            }}
          >
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>{headerContent}</Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                data-cy="seller_insights_drawer_close_button"
                onClick={() => {
                  onClose();
                }}
                size="large"
              >
                <ClearIcon fill={theme.palette.greys.silver} />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              m: 2,
              pt: 2,
              px: 3,
              pb: 3,
              borderRadius: theme.borderRadius,
              bgcolor: 'greys.white',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 44px)',
            }}
          >
            {loading && <Loading />}
            {!loading && (
              <Box sx={{ mb: 1 }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'baseline' }}>
                  <Box sx={{ fontSize: '16px', fontWeight: 700, fontFamily: theme.typography.fontFamily, mb: 2 }}>{`${client ? client.name : 'Error'}`}</Box>
                  {hasPermission('tenantManagement') && (
                    <Box sx={{ fontSize: '13px', fontWeight: 300, fontFamily: theme.typography.fontFamily, mb: 2 }}>
                      {`(id: ${client.id})`}
                    </Box>
                  )}
                </Box>
                <TabList color="primary" onChange={(_event, value) => handleTabChange(value)}>
                  <Tab label="Profile" value="1" data-cy="scan_rules_tab" />
                  {hasPermission('tenantManagement') && (
                    <Tab label={`Tiers`} value="2" data-cy="tiers_tab" />
                  )}
                  <Tab label={`Test Buy Credits`} value="3" data-cy="credits_tab" />
                  <Tab label={`Users (${client?.users?.length})`} value="4" data-cy="users_tab" />
                </TabList>
              </Box>
            )}
            {!loading && (
              <Scrollable transparent sx={{ borderRadius: '0' }}>
                {!loading && client && (
                  <Suspense fallback={<div />}>
                    <TabPanel sx={{ m: 0, p: 0 }} value="1">
                      <ClientProfile client={client} tenant={tenant} onUpdated={onUpdated} />
                    </TabPanel>
                    <TabPanel sx={{m:0, p:0}} value="2">
                      <Tiers client={client} />
                    </TabPanel>
                    <TabPanel sx={{m:0, p:0}} value="3">
                      <TestBuyCredits account={client} />
                    </TabPanel>
                    <TabPanel sx={{ m: 0, p: 0 }} value="4">
                      <Users accountId={client.id} tenant={tenant} clientMode selfId={user.profile.userId} admin={hasPermission('accountManagement')} />
                    </TabPanel>
                  </Suspense>
                )}
              </Scrollable>
            )}
          </Box>
        </Box>
      </TabContext>
    </Drawer>
  );
};

ClientDrawer.defaultProps = {
  id: null,
  tenantId: null,
};

ClientDrawer.propTypes = {
  id: PropTypes.number,
  tenantId: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ClientDrawer.defaultProps = {
  open: false,
};

export default ClientDrawer;
