import { secureGraphQLFetch } from './fetch-service';

const getUser = () => {
  const query = `query {
        getUser {
            firstName,
            lastName,
            email,
            id,
            federated,
            userId,
            tenants,
            disabled,
            roles {
              id
              name
              description
              permissions {
                id
                name
                description
              }
            }
            assignableRoles {
              id
              name
              description
              permissions {
                id
                name
                description
              }
            }
            configuredAbilities {
              id
              name
              description
            }
            availableAbilities {
              id
              name
              description
            }
            availableRegions {
              id
              currency
              baseUrl
              iso
              platform
              name
              nativeName
              manualTestBuy
              autoTestBuy
            }
            configuredRegions {
              id
              currency
              baseUrl
              platform
              iso
              name
              nativeName
              manualTestBuy
              autoTestBuy
            }
            availableAccounts {
              id
              accountId
              userId
              name
              logo
              tenantId
              tenantName
              tenantLogo
              tenantPath
            }
        }
      }`;
  return secureGraphQLFetch({ query }).then((result) => result?.getUser);
};

const getAvailableAccounts = ({ tenant }) => {
  const query = `query {
        getAccountsForUser(tenant: "${tenant}") {
            id
            accountId
            userId
            name
            logo
            tenantId
            tenantName
            tenantLogo
            tenantPath
        }
      }`;
  return secureGraphQLFetch({ query }).then((result) => result?.getAccountsForUser);
};


const updateUser = ({ id, accountId, email, firstName, lastName }) => {
  const query = `mutation{
            updateUserInAccount(
              id:${id}
              accountId:${accountId}
              email:${JSON.stringify(email) ?? null}
              firstName:${JSON.stringify(firstName) ?? null}
              lastName:${JSON.stringify(lastName) ?? null}
          ) { id }}`;
  return secureGraphQLFetch({ query }).then((result) => result?.updateUserInAccount);
};

const updateUserLoggedIn = () => {
  const query = `mutation{
        updateUserLoggedIn
      }`;
  return secureGraphQLFetch({ query }).then((result) => result?.updateUserLoggedIn);
};

const inviteUser = ({ accountId, inviteEmail, resend = false }) => {
  const query = `mutation{
    inviteUser(accountId: ${accountId}, inviteEmail:${JSON.stringify(inviteEmail)}, resend: ${
    resend ? 'true' : 'false'
  })
  }`;
  return secureGraphQLFetch({ query }).then((result) => result?.updateUserLoggedIn);
};

const changePassword = ({ accessToken, previousPass, newPass }) => {
  const query = `mutation{
    changePassword(
    AccessToken: "${accessToken}"
    PreviousPassword: ${previousPass}
    ProposedPassword: ${newPass})
  }`;

  return secureGraphQLFetch({ query }).then((result) => result?.changePassword);
};

export default { getUser, updateUser, updateUserLoggedIn, inviteUser, changePassword, getAvailableAccounts };
