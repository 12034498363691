import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Tooltip, Box, Checkbox, useTheme } from '@mui/material';
import { RegionDataContext } from 'contexts';
import { SellerName, ProductName, BuyBoxPercentage } from 'components';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import { isAmazonSeller } from 'utils/misc';
import SellerAuthorizationState from 'pages/Common/SellerAuthorizationState';

const InsightsOfferRow = ({ offer, onShowSeller, compact, changeChecked, selectedSellerId }) => {
  const { regionIso, regionCurrency } = useContext(RegionDataContext);
  const theme = useTheme();
  const isAmazon = isAmazonSeller({ sellerId: offer.sellerId, sellerName: offer.sellerName });
  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', gap: 1, width: '100%', alignItems: 'center' }}>
          {!isAmazon && (
            <Checkbox
              disabled={selectedSellerId && selectedSellerId !== offer.sellerId}
              sx={{
                width: '38px',
                opacity: selectedSellerId && selectedSellerId !== offer.sellerId ? 0.25 : 1,
              }}
              onChange={() => {
                if (changeChecked) {
                  changeChecked(offer.id);
                }
              }}
              data-cy="offer_checkbox"
              checked={offer.selected ?? false}
            />
          )}
          {isAmazon && <Box sx={{ width: '38px' }} />}
          <Box
            type="button"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: theme.colors.linkBlue,
              textDecoration: 'underline',
            }}
            onClick={() => {
              onShowSeller(offer.sellerId);
            }}
          >
            <SellerName
              sellerName={offer.sellerName || ''}
              sx={{
                display: 'block',
                fontWeight: '600',
                fontSize: '14px',
                color: '#0071DA',
                textDecoration: 'underline',
                lineHeight: '18px',
                maxHeight: '36px',
                overflow: 'hidden',
              }}
            />
          </Box>
          <SellerAuthorizationState
            globalAuth={offer.globalAuth}
            partialAuth={offer.sellerAuthorized}
          />
        </Box>
      </TableCell>
      <TableCell>
        <ProductName
          asin={offer.asin}
          productName={offer.name}
          sx={{
            display: 'block',
            fontWeight: '500',
            fontSize: '14px',
            color: '#0071DA',
            textDecoration: 'underline',
            lineHeight: '18px',
            maxHeight: '36px',
            overflow: 'hidden',
          }}
        />
      </TableCell>
      {!compact && (
        <>
          <TableCell align="right">
            {offer.hasMultipleOffers && offer.buyBoxWinPercentage > 0 && (
              <Tooltip
                title="This win rate is the average win rate across all of this sellers' offers"
                arrow
                placement="bottom"
                sx={{ cursor: 'default' }}
              >
                <Box
                  sx={{
                    cursor: 'default',
                    color: theme.colors.linkBlue,
                    textDecoration: 'underline',
                  }}
                >
                  {offer.buyBoxWinPercentage}%*
                </Box>
              </Tooltip>
            )}
            {(!offer.hasMultipleOffers ||
              (offer.hasMultipleOffers &&
              (offer.buyBoxWinPercentage === 0 || offer.buyBoxWinPercentage === null))) && (
              <BuyBoxPercentage percentage={offer.buyBoxWinPercentage} />
            )}
          </TableCell>
          <TableCell>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '3px', justifyContent: 'flex-end' }}
            >
              <Box>{offer.score}</Box>
            </Box>
          </TableCell>
          <TableCell>
            {Intl.NumberFormat(regionIso, {
              style: 'currency',
              currency: regionCurrency,
            }).format(offer.price.toFixed(2))}
          </TableCell>
          <TableCell>
            {Intl.NumberFormat(regionIso, {
              style: 'currency',
              currency: regionCurrency,
            }).format(offer.mapPrice.toFixed(2))}
          </TableCell>
        </>
      )}
      <TableCell>
        {Intl.NumberFormat(regionIso, {
          style: 'currency',
          currency: regionCurrency,
        }).format(offer.diff.toFixed(2))}
      </TableCell>
      <TableCell>
        <SellerAndOfferMenu offers={[offer]} />
      </TableCell>
    </TableRow>
  );
};

InsightsOfferRow.propTypes = {
  offer: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onShowSeller: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

InsightsOfferRow.defaultProps = {
  compact: false,
};

export default InsightsOfferRow;
