import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Tooltip } from '@mui/material';
import { RegionDataContext } from 'contexts';
import { capitalizeFirstLetter } from 'utils/strings';

const ProductName = ({ asin, productName, sx, onClick }) => {
  const length = 9999;
  const { platform, platformTerms, productLink } = useContext(RegionDataContext);

  const truncatedName = (name) =>
    name?.length > length ? `${name?.substr(0, length - 1)}...` : name;

  return (
    <>
      <Tooltip
        title={
          <Box>
            {productName}
            {!productName && `No longer listed on ${capitalizeFirstLetter(platform)}`}
            {asin && (
              <>
                <br />
                <br />
                {platformTerms.productTermId}: {asin}
              </>
            )}
          </Box>
        }
        arrow
        placement="bottom"
        enterDelay={500}
        enterNextDelay={500}
        sx={{ cursor: 'default' }}
      >
        <Box>
          <Link
            sx={sx}
            href={`${productLink}${asin}`}
            target="_blank"
            onClick={onClick}
            underline="hover"
            data-cy="asin_product_name"
          >
            {!productName && `${asin} (Unlisted)`}
            {truncatedName(productName)}
          </Link>
        </Box>
      </Tooltip>
    </>
  );
};

ProductName.defaultProps = {
  asin: null,
  productName: null,
  sx: undefined,
  onClick: undefined,
};

ProductName.propTypes = {
  asin: PropTypes.string,
  productName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  sx: PropTypes.oneOfType([PropTypes.object]),
  onClick: PropTypes.func,
};

export default ProductName;
