import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Content, Top, Scrollable, Header, Loading, LinkButton } from 'components';
import { Typography, Box, Tab } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { useLazyQuery, gql } from '@apollo/client';
import { ToastDataContext } from 'contexts';
import ClientsContent from 'pages/Clients/ClientsContent';
import TenantProfile from './TenantProfile';
import PermittedDomains from './PermittedDomains';

const Tenant = () => {

  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const [tenant, setTenant] = useState(null);
  const { id, routeTab } = useParams();
  const history = useHistory();
  const location = useLocation();

  const routeTabMapping = {
    clients: '1',
    profile: '2',
    domains: '3',
  };
  const [tab, setTab] = useState(routeTabMapping[routeTab] ?? routeTabMapping.clients);

  const handleTabChange = (newValue) => {
    setTab(newValue);
    const mappings = Object.entries(routeTabMapping);
    for (let i = 0; i < mappings.length; i += 1) {
      const [key, value] = mappings[i];
      if (value === newValue) {
        const newPath = `/tenants/${id}/${key}`;
        if (newPath !== location.pathname) {
          history.push(newPath);
        }
      }
    }
  };

  const GET_TENANT_QUERY = gql`
      query GetTenant($id: ID!) {
        getTenant(id: $id) {
          id
          tenant
          title
          buttonColor
          navColor
          loginLogo
          navLogo
          clientCount
          asinCount
          configuredFeatures {
            id
            name
            description
          }
          configuredRegions {
            id
            currency
            baseUrl
            iso
            platform
            name
            nativeName
          }
          permittedDomains {
            id
            domain
          }
        }
      }
    `;

  const processData = (dataToProcess) => {
    if (dataToProcess.getTenant) {
      setTenant(dataToProcess.getTenant);
    }
    setLoading(false);
  };

  const [fetchData] = useLazyQuery(GET_TENANT_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { id },
    skip: !id,
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Content>
      <TabContext value={tab}>
        <Top>
          <Header
            title={
              <Box>
                {loading && (
                  `Tenants`
                )}
                {!loading && (
                  <Box sx={{ display: 'flex' }}>
                    <LinkButton
                      hyperStyle
                      sx={{ color: (theme) => theme.colors.linkBlue }}
                      onClick={() => history.push('/Tenants')}
                    >
                      <Typography variant="h2" sx={{ color: (theme) => theme.colors.linkBlue }}>{`Tenants`}</Typography>
                    </LinkButton>
                  </Box>
                )}
              </Box>
            }
          >
            {loading && <Loading />}
            {!loading && (
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                  <Box sx={{ fontWeight: '600', fontSize: '18px', mb: '3px' }} data-cy="tenant_header_title">{`${tenant ? `${tenant.title?.length > 0 ? tenant.title : '(no name)'}` : 'Error'}`}</Box>
                </Box>
                <TabList color="primary" onChange={(_event, value) => handleTabChange(value)}>
                  <Tab label="Clients" value={routeTabMapping.clients} data-cy="clients_tab" />
                  <Tab label="Profile" value={routeTabMapping.profile} data-cy="profile_tab" />
                  {tenant.tenant !== 'ipsecure' && (
                    <Tab label="Email Domains" value={routeTabMapping.domains} data-cy="domains_tab" />
                  )}
                </TabList>
              </Box>
            )}
          </Header>
        </Top>
        {!loading && (
          <Scrollable>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.clients}>
              <ClientsContent tenant={tenant} onUpdated={() => fetchData()} />
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.profile}>
              <TenantProfile tenant={tenant} onUpdated={() => fetchData()} />
            </TabPanel>
            {tenant.tenant !== 'ipsecure' && (
              <TabPanel sx={{ m: 0, p: 0 }} value={routeTabMapping.domains}>
                <PermittedDomains tenant={tenant} onUpdated={() => fetchData()} />
              </TabPanel>
            )}
          </Scrollable>
        )}
      </TabContext>
    </Content>
  );
};

export default Tenant;
