/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useContext } from 'react';
import { Content, Top, Scrollable, Header, Loading } from 'components';
import {
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableSortLabel,
  Button,
  Box,
  Tooltip,
  useTheme,
} from '@mui/material';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as DownloadIcon } from 'assets/big-download.svg';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { ToastDataContext, RegionDataContext } from 'contexts';
import { useParams, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { useQuery, gql } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { formatStringForCSV } from 'utils/strings';
import { formatDateTime } from 'utils/dates';
import dayjs from 'dayjs';
import SellerDrawer from './SellerDrawer';
import SellerRow from './SellerRow';

const Sellers = () => {
  const { id } = useParams();
  const history = useHistory();
  const [showSellerId, setShowSellerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sellerData, setSellerData] = useState(null);
  const [zeroTransition, setZeroTransition] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const { platform, platformTerms, regionIso, regionCurrency } = useContext(RegionDataContext);
  const [searchText, setSearchText] = useState('');
  const scrollPageLength = 20;
  const [limit, setLimit] = useState(scrollPageLength);
  const [sortColumn, setSortColumn] = useState('currentScore');
  const [selected, setSelected] = useState(0);
  const [sortOrder, setSortOrder] = useState('desc');
  const [loadingSales, setLoadingSales] = useState(true);
  const [lostSales, setLostSales] = useState(null);
  const theme = useTheme();
  const [tabs] = useState([
    { index: 0, name: 'All', datacy: "All" },
    { index: 1, name: 'Buy Box Winners', datacy: "BuyBoxWinners" },
    { index: 2, name: 'Authorized Sellers', datacy: "AuthorizedSellers" },
  ]);

  const [headCells, setHeadCells] = useState([]);

  const initializeData = () => {
    setHeadCells([
      { id: 'name', label: 'Seller', numeric: false, sortable: true, width: '25%', datalabel: "Seller" },
      { id: 'categories', label: 'Categories', numeric: false, sortable: false, width: '15%', datalabel: "Categories" },
      { id: 'tags', label: 'Tags', numeric: false, sortable: false, width: '15%', datalabel: "Tags" },
      {
        id: 'buyBoxWinRate',
        label: 'Win Rate',
        tooltip: `The latest percentage of the time the individual seller has won the buy box for attached ${platformTerms.productTermPlural} that are not suppressed.`,
        numeric: true,
        sortable: true,
        width: '15%',
        datalabel: "Win Rate"
      },
      { id: 'currentScore', label: 'Score', numeric: true, sortable: true, width: '5%', datalabel: "Score" },
      { id: 'asins', label: `${platformTerms.productTermPlural}`, numeric: true, sortable: true, width: '5%', datalabel: "ASIN" },
      {
        id: 'lostSales',
        label: 'Lost Sales',
        tooltip: '7-Day Estimated Sales Taken',
        numeric: true,
        sortable: true,
        width: '15%',
        datalabel: "Lost Sales"
      },
      { id: 'action', label: '', numeric: false, sortable: false, width: '5%' },
    ]);
  };

  const sortData = ({ column, dataToProcess, order }) => {
    const isAsc = order === 'asc';
    let isNumeric = false;
    for (let i = 0; i < headCells.length; i += 1) {
      if (headCells[i].id === column) {
        isNumeric = headCells[i].numeric;
        break;
      }
    }
    const sorted = [].concat(dataToProcess).sort((a, b) => {
      if (column === 'currentScore') {
        if (a[column].score === b[column].score) {
          return a.name.localeCompare(b.name);
        }
        if (!isAsc) {
          return b[column].score - a[column].score;
        }
        return a[column].score - b[column].score;
      }
      if (column === 'asins') {
        if (a[column].length === b[column].length) {
          return a.name.localeCompare(b.name);
        }
        if (!isAsc) {
          return b[column].length - a[column].length;
        }
        return a[column].length - b[column].length;
      }
      if (isNumeric) {
        if (a[column] === b[column]) {
          return a.name.localeCompare(b.name);
        }
        if (!isAsc) {
          return b[column] - a[column];
        }
        return a[column] - b[column];
      }
      if (!isAsc) {
        return b[column].localeCompare(a[column]);
      }
      return a[column].localeCompare(b[column]);
    });
    return sorted;
  };

  const filterAndSort = ({ tab, dataToFilter, keywords, column, order }) => {
    // Filter the data based on keywords and selected tab
    const searchData = dataToFilter?.filter((seller) => {
      if (tab === 2 && !seller.authorized) {
        return false;
      }
      if (tab === 1 && !seller.buyBoxWinRate) {
        return false;
      }
      const tokens = keywords?.toLowerCase().split(' ');
      let tokensFound = 0;
      for (let i = 0; i < tokens.length; i += 1) {
        const token = tokens[i];
        if (seller.objString.toLowerCase().includes(token)) {
          tokensFound += 1;
        }
      }
      return tokensFound === (tokens ? tokens?.length : 0);
    });

    // Sort based on column and order
    const sortedData = sortData({ column, dataToProcess: searchData, order });
    setFiltered(sortedData);
  };

  const sortHandler = (headCell, dataToProcess, order) => {
    if (!headCell.sortable) {
      return;
    }
    const isAsc = sortColumn === headCell.id && order === 'asc';
    const sortingOrder = isAsc ? 'asc' : 'desc';
    setSortOrder(sortingOrder);
    setSortColumn(headCell.id);
    filterAndSort({
      tab: selected,
      dataToFilter: sellerData,
      keywords: searchText,
      column: headCell.id,
      order: sortingOrder,
    });
  };

  const combineData = () => {
    if (lostSales && sellerData) {
      const clonedData = sellerData.concat();
      for (let i = 0; i < clonedData.length; i += 1) {
        const seller = clonedData[i];
        seller.lostSales = 0;
        for (let j = 0; j < lostSales.length; j += 1) {
          const lostSale = lostSales[j];
          if (lostSale.sellerId === seller.id) {
            seller.lostSales = lostSale.contestedLost;
            break;
          }
        }
      }
      setSellerData(clonedData);
      filterAndSort({
        tab: selected,
        dataToFilter: clonedData,
        keywords: searchText,
        column: sortColumn,
        order: sortOrder,
      });
    }
  };

  const processData = (dataToProcess) => {
    const sellers = dataToProcess.getSellersForAccount.map((s) => ({
      ...s, 
      tags: s.systemTags.concat(s.tags),
    }))
    const sellersWithString = sellers.map((s) => ({
      ...s,
      objString: [JSON.stringify(s.tags), s.businessName, s.id, s.name, JSON.stringify(s.categories.name), JSON.stringify(s.asins.map(a => a.asin))].join(' '),
    }));
    if (lostSales) {
      for (let i = 0; i < sellersWithString.length; i += 1) {
        const seller = sellersWithString[i];
        seller.lostSales = 0;
        for (let j = 0; j < lostSales.length; j += 1) {
          const lostSale = lostSales[j];
          if (lostSale.sellerId === seller.id) {
            seller.lostSales = lostSale.contestedLost;
            break;
          }
        }
      }
    }
    setSellerData(sellersWithString);
    filterAndSort({
      tab: selected,
      dataToFilter: sellersWithString,
      keywords: searchText,
      column: sortColumn,
      order: sortOrder,
    });
    setLoading(false);
  };

  const GET_SELLERS_QUERY = gql`
    query GetSellersWithCategories {
      getSellersForAccount {
        actionsTaken
        lastActionTakenDate
        authorized
        asins {
          asin
          categoryId
        }
        businessAddress
        businessDetails
        businessName
        buyBoxWinRate
        coordinates
        id
        name
        sellerUrl
        globalAuth
        tags
        systemTags
        categories {
          uniqueId
          id
          name
          authorized
        }
        currentScore {
          score
          harvestDate
        }
      }
    }
  `;
  const { data } = useQuery(GET_SELLERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const getDateRanges = () => {
    const date = new Date();
    const dateStart = dayjs(date).add(-6, 'day').startOf('day').toISOString();
    const dateEnd = dayjs(date).endOf('day').toISOString();
    return { dateStart, dateEnd };
  };

  const { dateStart, dateEnd } = getDateRanges();

  const processLostSalesData = (dataToProcess) => {
    if (dataToProcess) {
      const lostSalesData = dataToProcess.getTopLostBuyBox;
      setLostSales(lostSalesData);
      setLoadingSales(false);
    }
  };

  const GET_LOST_SALES_QUERY = gql`
    query GetLostSales($dateStart: DateTime, $dateEnd: DateTime) {
      getTopLostBuyBox(dateStart: $dateStart, dateEnd: $dateEnd) {
        sellerId
        contestedLost
      }
    }
  `;

  useQuery(GET_LOST_SALES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { dateStart, dateEnd },
    onCompleted: processLostSalesData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const onSearch = (text) => {
    setSearchText(text);
    filterAndSort({
      tab: selected,
      dataToFilter: sellerData,
      keywords: text,
      column: sortColumn,
      order: sortOrder,
    });
  };

  const handleSelectTab = (tab) => {
    if (!headCells[3]) {
      return;
    }
    setSelected(tab);
    let column = headCells[3].id;
    const order = 'desc';
    if (tab === 0) {
      column = headCells[4].id;
    }
    setSortColumn(column);
    setSortOrder(order);
    if (!loading) {
      filterAndSort({ tab, dataToFilter: sellerData, keywords: searchText, column, order });
    }
  };

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);

  useEffect(() => {
    if (lostSales) {
      combineData();
    }
  }, [lostSales]);

  useEffect(() => {
    initializeData();
  }, []);

  const loadMore = () => {
    if (limit < filtered.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > filtered.length) {
        newLimit = filtered.length;
      }
      setLimit(newLimit);
    }
  };

  const onShowSeller = (sellerId) => {
    setShowSellerId(sellerId);
  };

  useEffect(() => {
    if (id) {
      setZeroTransition(0);
      onShowSeller(id);
    }
  }, []);

  const exportSellers = (sellersToExport) => {
    const csvData = [
      [
        'Seller Name',
        'Seller ID',
        'SellerLink',
        'Categories',
        'Tags',
        'Win Rate',
        `${platformTerms.productTermPlural}`,
        '7-Day Estimated Sales Taken',
        'Actions Taken',
        'Business Name',
        'Business Address',
      ],
    ];

    for (let i = 0; i < sellersToExport.length; i += 1) {
      const seller = sellersToExport[i];
      const companyName = seller?.businessName;
      const categories = seller.categories.map((c) => `“${c.name}”`).join(', ');
      const tags = seller.tags.join(', ');
      csvData.push([
        formatStringForCSV(seller.name),
        formatStringForCSV(seller.id),
        formatStringForCSV(seller.sellerUrl),
        formatStringForCSV(categories),
        formatStringForCSV(tags),
        seller.buyBoxWinRate,
        seller.asins.length,
        Intl.NumberFormat(regionIso, {
          style: 'currency',
          currency: regionCurrency,
          maximumFractionDigits: 0,
        }).format(seller.lostSales),
        seller.actionsTaken,
        formatStringForCSV(companyName),
        formatStringForCSV(seller.businessAddress),
      ]);
    }
    return csvData;
  };

  const exportFilename = () =>
    `${platform}-${regionIso}-SellerExport-${tabs[selected].name}-(${formatDateTime(new Date())}).csv`;

  return (
    <Content>
      <Top>
        <Header title="Sellers List">
          <Tabs color="primary" value={selected} onChange={(_event, tab) => handleSelectTab(tab)}>
            {tabs.map((tab) => (
              <Tab key={tab.index} label={tab.name} data-cy={`sellers_tabs_${tab.datacy}`} />
            ))}
          </Tabs>
        </Header>
      </Top>
      <Scrollable data-cy="seller_scroll">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: 'greys.silver', fontStyle: 'italic' }}
            data-cy="sellers_total"
          >
            {filtered.length ? `Total ${filtered.length?.toLocaleString('en-US')}` : ''}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              disabled={loading}
              type="text"
              onChange={(event) => onSearch(event.target.value)}
              placeholder="Search"
              data-cy="sellers_search_bar"
              value={searchText}
              sx={{ width: '30ch' }}
              InputProps={{
                sx: { pl: 1, minHeight: '100%', height: '100%' },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fill={theme.palette.greys.silver} />
                  </InputAdornment>
                ),
                endAdornment: searchText ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        onSearch('');
                      }}
                      size="large"
                    >
                      <ClearIcon fill={theme.palette.greys.silver} />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  ''
                ),
              }}
            />
            <Box sx={{ pointerEvents: loading ? 'none' : 'all' }}>
              <CSVLink target="_blank" filename={exportFilename()} data={exportSellers(filtered)}>
                <Button
                  disabled={loading || loadingSales}
                  sx={{ borderColor: 'greys.lightGrey' }}
                  variant="outlined"
                  startIcon={
                    <DownloadIcon
                      style={{ opacity: loading ? 0.35 : 1.0 }}
                      fill={theme.palette.greys.black}
                    />
                  }
                  data-cy="asin_manager_download"
                >
                  Download
                </Button>
              </CSVLink>
            </Box>
          </Box>
        </Box>
        {loading && <Loading />}
        {!loading && headCells && (
          <Box sx={{ overflow: 'scroll', height: 'calc(100vh - 202px)' }} data-cy="sellers_scroll">
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={true || false}
              loader={limit < filtered.length && <Loading key={0} />}
              useWindow={false}
            >
              <TableContainer sx={{ overflowX: 'initial' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {headCells && headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          sortDirection={sortColumn === headCell.id ? sortOrder : false}
                          width={headCell.width}
                        >
                          <TableSortLabel
                            hideSortIcon={!headCell.sortable}
                            data-cy={headCell.datalabel}
                            active={headCell.sortable && sortColumn === headCell.id}
                            direction={sortColumn === headCell.id ? sortOrder : 'asc'}
                            onClick={() => {
                              sortHandler(headCell, filtered, sortOrder === 'asc' ? 'desc' : 'asc');
                            }}
                          >
                            <Tooltip title={headCell.tooltip}>
                              <Box sx={{ display: 'inline-block' }}>
                                {headCell.id !== 'lostSales' && headCell.label}
                                {headCell.id === 'lostSales' && !loadingSales && headCell.label}
                                {headCell.id === 'lostSales' && loadingSales && (
                                  <Loading sx={{ opacity: 0.5 }} small />
                                )}
                                {headCell.tooltip && !loadingSales && (
                                  <InfoIcon sx={{ ml: '4px', width: '13px', height: '13px' }} />
                                )}
                              </Box>
                            </Tooltip>
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody data-cy="sellers_table">
                    {filtered.slice(0, limit).map((seller, index) => (
                      <SellerRow
                        key={seller.id}
                        seller={seller}
                        index={index}
                        onShowSeller={(sellerId) => {
                          history.replace(`/sellers/${sellerId}`);
                          onShowSeller(sellerId);
                        }}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          </Box>
        )}
      </Scrollable>
      <SellerDrawer
        transitionDuration={zeroTransition}
        id={showSellerId}
        open={showSellerId != null}
        onClose={() => {
          setZeroTransition(null);
          setShowSellerId(null);
          history.replace('/sellers');
        }}
        large
      />
    </Content>
  );
};

export default Sellers;
