import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  IconButton,
  Box,
  styled,
  useTheme,
  Typography,
  MenuItem,
  Button,
} from '@mui/material';
import { Loading, FeatureTestDialog, CustomDialog, CustomDialogContent } from 'components';
import { ToastDataContext, AuthDataContext, RegionDataContext } from 'contexts';
import LetterEditor from 'pages/Common/LetterEditor';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as MailIcon } from 'assets/mail.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as LinkIcon } from 'assets/link.svg';
import { ReactComponent as SendLetterIcon } from 'assets/send-letter.svg';
import { ReactComponent as TestBuyIcon } from 'assets/shopping-cart.svg';
import { ReactComponent as PolicyViolationIcon } from 'assets/enforcement-policy-violation.svg';
import { ReactComponent as LicenseViolationIcon } from 'assets/enforcement-license-violation.svg';
import { ReactComponent as AddToQueueIcon } from 'assets/sidenav/enforcements-not-active.svg';
import PolicyViolation from 'pages/Enforcements/Violations/PolicyViolation';
import PurchaseTestBuy from 'pages/TestBuys/TestBuyPurchase/PurchaseTestBuy';
import { gql, useMutation } from '@apollo/client';
import { isAmazonSeller } from 'utils/misc';
import { capitalizeFirstLetter } from 'utils/strings';
import ChangeAuthSeller from './ChangeAuthSeller';
import AddToQueueDialog from './AddToQueueDialog';

const StyledMenuItem = styled(MenuItem)({
  padding: '6px 24px',
});

const StyledIconBox = styled(Box)({
  width: '32px',
  minWidth: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '-4px',
});

const SellerAndOfferMenu = ({
  asin,
  seller,
  enforcement,
  offers,
  onEditMap,
  onDelete,
  button,
  isActionQueue,
  onActionTaken,
  isOfferProfile,
  sx,
  onAuthorizedChanged,
  onEditFields,
  editFieldId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [testBuyOpen, setTestBuyOpen] = useState(null);
  const [letterOpen, setLetterOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [policyViolation, setPolicyViolation] = useState(false);
  const [licenseViolation, setLicenseViolation] = useState(false);
  const [customPlanUpgradeOpen, setCustomPlanUpgradeOpen] = useState(false);
  const [showAddToQueue, setShowAddToQueue] = useState(false);
  const [busy, setBusy] = useState(null);
  const { setToast } = useContext(ToastDataContext);
  const { account, hasAbility } = useContext(AuthDataContext);
  const { platform, regionUrl, platformTerms } = useContext(RegionDataContext);
  const theme = useTheme();

  const modifiedOffers = (() => {
    if (offers) {
      const modified = offers.map((offer) => {
        const val = JSON.parse(JSON.stringify(offer));
        if (!offer.price && offer.history) {
          const sortedHistory = offer.history
            .concat()
            .sort((a, b) => new Date(b.harvestDate) - new Date(a.harvestDate));
          val.price = sortedHistory[0].price;
          val.shippingPrice = sortedHistory[0].shippingPrice;
          val.harvestDate = sortedHistory[0].harvestDate;
        }
        return val;
      });
      return modified;
    }
    return null;
  })();

  const sellerId = (() => {
    if (enforcement && enforcement.offers[0].sellerId) {
      return enforcement.offers[0].sellerId;
    }
    if (modifiedOffers && modifiedOffers[0].sellerId) {
      return modifiedOffers[0].sellerId;
    }
    if (seller && seller.id) {
      return seller.id;
    }
    return null;
  })();

  const ADD_TO_QUEUE_MUTATION = gql`
    mutation AddToEnforcementQueue($offers: [EnforcementOfferInput], $notes: String) {
      addToEnforcementQueue(offers: $offers, notes: $notes) {
        id
      }
    }
  `;
  const [addToEnforcementQueueMutation] = useMutation(ADD_TO_QUEUE_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const sellerName = (() => {
    if (enforcement && enforcement.offers[0].sellerName) {
      return enforcement.offers[0].sellerName;
    }
    if (modifiedOffers && modifiedOffers[0].sellerName) {
      return modifiedOffers[0].sellerName;
    }
    if (seller && seller.name) {
      return seller.name;
    }
    return null;
  })();

  const offerId = (() => {
    if (enforcement && enforcement.offers[0].offerId) {
      return enforcement.offers[0].offerId;
    }
    if (modifiedOffers && modifiedOffers[0].offerId) {
      return modifiedOffers[0].offerId;
    }
    return null;
  })();

  const isAmazon = isAmazonSeller({ sellerId, sellerName });

  const multipleOffers = enforcement?.offers?.length > 1;

  const policyFeatureDescription =
    `We are evaluating clients' interest in this feature. ` +
    `We plan to offer users the ability to submit Seller Policy Violations through Amazon.  Click I'm Interested to let us know we should continue development on this feature.`;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTestBuy = () => {
    handleClose();
    setTestBuyOpen(true);
  };

  const handleTestBuyClose = (sent) => {
    setTestBuyOpen(false);
    if (sent && onActionTaken) {
      onActionTaken({ offerId, enforcement });
    }
  };

  const handleVisitSeller = () => {
    handleClose();
    window.open(`${regionUrl}/sp?_encoding=UTF8&seller=${sellerId}`);
  };

  const handleEditMap = () => {
    handleClose();
    onEditMap(asin);
  };

  const handleDelete = () => {
    handleClose();
    if (enforcement) {
      onDelete(enforcement);
    } else {
      onDelete(asin);
    }
  };

  const addToQueue = async (notes) => {
    setBusy(true);
    addToEnforcementQueueMutation({
      variables: {
        offers: offers.map((o) => ({ asin: o.asin, offerId: o.offerId, sellerId: o.sellerId })),
        notes: notes ?? '',
      },
      onCompleted: (data) => {
        if (data.addToEnforcementQueue && data.addToEnforcementQueue.id !== null) {
          setToast({ message: `Added offer to Action Queue: ${modifiedOffers[0].name}` });
        } else {
          setToast({
            type: 'error',
            message: `We're sorry, an error has been encountered while adding your item to the queue. Please contact support. Code AIQ1`,
          });
          console.error(
            'SellerandOfferMenu: no result back from creating an action queue item from the seller/offer menu',
            {
              offers: JSON.stringify(offers),
            },
          );
          console.error(
            'SellerandOfferMenu: no result back from creating an action queue item from the seller/offer menu',
            {
              sellerId,
              asin: modifiedOffers[0].asin,
              offerId,
            },
          );
        }
        setBusy(false);
      },
    });
  };

  const handleAddToQueue = () => {
    handleClose();
    setShowAddToQueue(true);
  };

  const handleSendLetter = () => {
    handleClose();
    setLetterOpen(true);
  };

  const handleSendEmail = () => {
    handleClose();

    if (account.customPlan) {
      setEmailOpen(true);
    } else {
      setCustomPlanUpgradeOpen(true);
    }
  };

  const handlePlanNav = () => {
    setCustomPlanUpgradeOpen(false);
    window.location.assign('/settings/plan');
  };

  const handleLicenseViolation = () => {
    handleClose();
    setLicenseViolation(true);
  };

  const handlePolicyViolation = () => {
    handleClose();
    setPolicyViolation(true);
  };

  const handleEditFields = () => {
    handleClose();
    onEditFields(editFieldId);
  }

  return (
    <Box sx={{ minWidth: '38px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '30px',
          maxHeight: '30px',
          ...sx,
        }}
      >
        {busy && <Loading small />}
        {!busy && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {button && <Box onClick={handleMenu}>{button}</Box>}
            {!button && (
              <IconButton size="small" onClick={handleMenu}>
                <MoreHorizIcon data-cy="asin_options" fill={theme.palette.greys.silver} />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      {Boolean(anchorEl) && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          {(modifiedOffers || enforcement) && !isAmazon && (
            <Box>
              {(hasAbility('sendLetter') ||
                hasAbility('sendEmail') ||
                hasAbility('createIpViolation') ||
                hasAbility('makeTestBuy') ||
                !enforcement) && (
                <Typography
                  sx={{
                    bgcolor: 'greys.backgroundGrey',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    fontWeight: '600',
                    p: '4px 25px',
                    color: 'greys.silver',
                  }}
                >
                  Enforcements
                </Typography>
              )}
              {!enforcement && (
                <StyledMenuItem onClick={handleAddToQueue} sx={{ p: '6px 24px' }} data-cy="add_to_queue_option">
                  <StyledIconBox sx={{ transform: 'scale(0.75)' }}>
                    <AddToQueueIcon style={{ fill: theme.palette.primary.main }} />
                  </StyledIconBox>
                  Add to Queue
                </StyledMenuItem>
              )}

              {hasAbility('sendLetter') && (
                <StyledMenuItem onClick={handleSendLetter} data-cy="send_letter_button">
                  <StyledIconBox>
                    <SendLetterIcon style={{ fill: theme.palette.primary.main }} />
                  </StyledIconBox>
                  Send Letter
                </StyledMenuItem>
              )}
              {hasAbility('sendEmail') && (
                <StyledMenuItem onClick={handleSendEmail} data-cy="enforcements_send_email">
                  <StyledIconBox>
                    <MailIcon style={{ fill: theme.palette.primary.main }} />
                  </StyledIconBox>
                  Send Email
                </StyledMenuItem>
              )}
              {!multipleOffers && hasAbility('createIpViolation') && (
                <StyledMenuItem onClick={handleLicenseViolation} data-cy="enforcements_amazon_ip">
                  <StyledIconBox>
                    <LicenseViolationIcon style={{ fill: theme.palette.primary.main }} />
                  </StyledIconBox>
                  {capitalizeFirstLetter(platform)} IP Violation
                </StyledMenuItem>
              )}
              {!multipleOffers && hasAbility('createIpViolation') && (
                <StyledMenuItem onClick={handlePolicyViolation}>
                  <StyledIconBox>
                    <PolicyViolationIcon style={{ fill: theme.palette.primary.main }} />
                  </StyledIconBox>
                  Seller Policy Violation
                </StyledMenuItem>
              )}
              {!isOfferProfile && hasAbility('makeTestBuy') && !multipleOffers && (
                <StyledMenuItem onClick={handleTestBuy}>
                  <StyledIconBox>
                    <TestBuyIcon style={{ fill: theme.palette.primary.main }} />
                  </StyledIconBox>
                  Make Test Buy
                </StyledMenuItem>
              )}
            </Box>
          )}
          {(((modifiedOffers || seller) && !(modifiedOffers && seller)) || enforcement) &&
            anchorEl && (
              <ChangeAuthSeller
                onAuthorizedChanged={onAuthorizedChanged}
                enforcement={enforcement}
                offers={modifiedOffers}
                seller={seller}
                onClose={handleClose}
                sellerId={sellerId}
              />
            )}
          {(((modifiedOffers || seller) && !(modifiedOffers && seller)) || enforcement) &&
            !anchorEl && (
              <Box sx={{ height: '92px' }}>
                <Typography
                  sx={{
                    bgcolor: 'greys.backgroundGrey',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    fontWeight: '600',
                    minWidth: '470px',
                    maxMidth: '470px',
                    p: '4px 25px',
                    color: 'greys.silver',
                  }}
                >
                  Choose Authorized Categories
                </Typography>
                <Box sx={{ m: 2 }}>
                  <Loading small />
                </Box>
              </Box>
            )}
          {(modifiedOffers || seller) && !(modifiedOffers && seller) && !isAmazon && (
            <Typography
              sx={{
                bgcolor: 'greys.backgroundGrey',
                textTransform: 'uppercase',
                fontSize: '12px',
                fontWeight: '600',
                p: '4px 25px',
                color: 'greys.silver',
              }}
            >
              Other
            </Typography>
          )}
          {asin && !asin?.parent && hasAbility('asinManagement') && (
            <StyledMenuItem onClick={handleEditMap} data-cy="edit_asin_button">
              <StyledIconBox>
                <EditIcon style={{ fill: theme.palette.primary.main }} />
              </StyledIconBox>
              Edit {platformTerms.productTerm}
            </StyledMenuItem>
          )}
          {(seller || modifiedOffers) && !(modifiedOffers && seller) && !isAmazon && (
            <StyledMenuItem onClick={handleVisitSeller} data-cy="visit_seller_button">
              <StyledIconBox>
                <LinkIcon style={{ fill: theme.palette.primary.main }} />
              </StyledIconBox>
              Visit Seller
            </StyledMenuItem>
          )}
          {asin && hasAbility('asinManagement') && (
            <Box>
              {!asin?.parent && (
                <Box
                  sx={{
                    width: '100%',
                    m: 0,
                    p: 0,
                    borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
                  }}
                />
              )}
              <StyledMenuItem onClick={handleDelete} data-cy="asin_delete">
                <StyledIconBox>
                  <DeleteIcon style={{ fill: theme.palette.primary.main }} />
                </StyledIconBox>
                Delete
              </StyledMenuItem>
            </Box>
          )}
          {onEditFields && (
            <Box>
              <Box
                sx={{
                  width: '100%',
                  m: 0,
                  p: 0,
                  borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
                }}
              />
              <StyledMenuItem onClick={handleEditFields}>
                <StyledIconBox data-cy="edit_field">
                  <EditIcon style={{ fill: theme.palette.primary.main }} />
                </StyledIconBox>
                Edit Info
              </StyledMenuItem>
            </Box>
          )}
          {enforcement && isActionQueue && (
            <Box>
              {!asin?.parent && (
                <Box
                  sx={{
                    width: '100%',
                    m: 0,
                    p: 0,
                    borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
                  }}
                />
              )}
              <StyledMenuItem onClick={handleDelete}>
                <StyledIconBox data-cy="asin_delete">
                  <DeleteIcon style={{ fill: theme.palette.primary.main }} />
                </StyledIconBox>
                Delete
              </StyledMenuItem>
            </Box>
          )}
        </Menu>
      )}
      {customPlanUpgradeOpen && (
        <CustomDialog
          title="Sending email letters requires a premium subscription"
          onClose={() => {
            setCustomPlanUpgradeOpen(false);
          }}
          open={customPlanUpgradeOpen}
          maxWidth="xs"
          content={
            <CustomDialogContent>
              <Typography variant="body1">
                Upgrade your current plan to gain access to this feature and more!
              </Typography>
            </CustomDialogContent>
          }
          actions={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                alignItems: 'bottom',
              }}
            >
              <Button onClick={handlePlanNav}>Upgrade your plan!</Button>
            </div>
          }
        />
      )}
      {testBuyOpen && (
        <PurchaseTestBuy
          offerId={offerId}
          sellerId={sellerId}
          onClose={handleTestBuyClose}
          enforcementId={enforcement?.id}
        />
      )}
      {letterOpen && (
        <LetterEditor
          isOpen
          sellerId={sellerId}
          sellerName={sellerName}
          offers={enforcement ? enforcement.offers : modifiedOffers}
          enforcementId={enforcement?.id}
          sendMode
          onClose={(sent) => {
            setLetterOpen(false);
            if (sent && onActionTaken) {
              onActionTaken({ offerId, enforcement });
            }
          }}
        />
      )}
      {emailOpen && (
        <LetterEditor
          isOpen
          sellerId={sellerId}
          sellerName={sellerName}
          offers={enforcement ? enforcement.offers : modifiedOffers}
          enforcementId={enforcement?.id}
          emailMode
          sellerEmail="testing@gmail.com"
          onClose={(sent) => {
            setEmailOpen(false);
            if (sent && onActionTaken) {
              onActionTaken({ offerId, enforcement });
            }
          }}
        />
      )}
      {licenseViolation && (
        <PolicyViolation
          enforcementId={enforcement?.id}
          isOpen
          offerId={offerId}
          sellerId={sellerId}
          sellerName={sellerName}
          asin={enforcement ? enforcement.offers[0].asin : modifiedOffers[0].asin}
          onClose={async (sent) => {
            if (sent && onActionTaken) {
              onActionTaken({ offerId, enforcement });
            }
            setLicenseViolation(false);
          }}
        />
      )}
      {policyViolation && (
        <FeatureTestDialog
          featureDescription={policyFeatureDescription}
          featureTitle="Seller Profile Violation"
          featureTestOpen
          handleClose={() => {
            setPolicyViolation(false);
          }}
        />
      )}
      {showAddToQueue && (
        <AddToQueueDialog
          onCancel={() => setShowAddToQueue(false)}
          onAdd={(notes) => {
            setShowAddToQueue(false);
            addToQueue(notes);
          }}
        />
      )}
    </Box>
  );
};

SellerAndOfferMenu.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
  offers: PropTypes.arrayOf(PropTypes.object),
  asin: PropTypes.oneOfType([PropTypes.object]),
  seller: PropTypes.oneOfType([PropTypes.object]),
  enforcement: PropTypes.oneOfType([PropTypes.object]),
  onEditMap: PropTypes.func,
  onDelete: PropTypes.func,
  isActionQueue: PropTypes.bool,
  onActionTaken: PropTypes.func,
  button: PropTypes.any,
  isOfferProfile: PropTypes.bool,
  onAuthorizedChanged: PropTypes.func,
};

SellerAndOfferMenu.defaultProps = {
  sx: null,
  offers: null,
  asin: null,
  seller: null,
  enforcement: null,
  onEditMap: null,
  onDelete: null,
  isActionQueue: false,
  onActionTaken: null,
  button: null,
  isOfferProfile: false,
  onAuthorizedChanged: null,
};

export default SellerAndOfferMenu;
