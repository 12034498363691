import React, { useContext } from 'react';
import { RegionDataContext, AuthDataContext } from 'contexts';
import { Box } from '@mui/material';

const AmazonRegionIcon = ({ size }) => {
  const iconSize = size ?? 16;
  const { regionIso } = useContext(RegionDataContext);
  const { region } = useContext(AuthDataContext);

  return (
    <Box mx={1}>
      {regionIso && region && (
        <img
          alt={`${region} flag`}
          src={`https://flagsapi.com/${regionIso}/flat/${iconSize}.png`}
        />
      )}
    </Box>
  );
};

export default AmazonRegionIcon;
