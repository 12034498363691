import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Tooltip, Box, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { RegionDataContext } from 'contexts';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import OfferIcons from 'pages/Common/OfferIcons';
import { ReactComponent as TempImg } from '../../../assets/giraffe-onboarding.svg';

const ProductInfo = ({ product, seller, hasEnoughCredits, fee, creditBalance, creditCost, shippingCost, price, currency }) => {
  const [subtotal, setSubtotal] = useState(0);
  const { regionIso } = useContext(RegionDataContext);
  const theme = useTheme();

  const formatPrice = (amount) =>
    `${Intl.NumberFormat(regionIso, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount)}`;

  useEffect(() => {
    if (!hasEnoughCredits) {
      setSubtotal(price + shippingCost + fee);
    }
  }, []);

  return (
    <>
      {product && seller && (
        <Grid
          spacing={1}
          container
          xs={12}
          alignItems="center"
          item
          sx={{
            bgcolor: 'greys.white',
            borderRadius: theme.largeBorderRadius,
            p: 2,
            m: 1.5,
          }}
        >
          <Grid xs={12} item>
            <Typography variant="h4">Product Order</Typography>
          </Grid>
          <Grid xs={2} item>
            {product.image ? (
              <img alt={product.name} src={product.image} width={125} />
            ) : (
              <TempImg />
            )}
          </Grid>
          <Grid container item xs={7} sx={{ pl: 3 }}>
            <Grid container item>
              <Grid xs={10} item>
                <Box sx={{ display: 'flex' }}>
                  <Typography variant="h4">
                    {seller.name}
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <OfferIcons offer={product} hideAuthorized />
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs={12}>
                <Grid xs={3} item>
                  <Typography variant="subtitle1">{product.asin}</Typography>
                </Grid>
                <Grid xs={9} container spacing={1} item justifyContent="flex-end">
                  <Grid item xs={2}>
                    <Typography variant="body1" sx={{ color: 'greys.grey' }}>
                      {product.variantTitle ? 'Variant' : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="body1"
                      sx={{
                        pr: 1,
                        color: 'greys.black',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                      }}
                    >
                      {product.variantTitle}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={10} item>
                <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                  {product.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={3} item>
            {!hasEnoughCredits && (
              <Grid container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip
                    title={
                      <Box>
                        You don&apos;t have enough credits to make this purchase
                        <br />
                        <br />
                        You can proceed with the selected card
                        <br />
                        or you can purchase more by contacting
                        <br />
                        support@ipsecure.com
                      </Box>
                    }
                    arrow
                    placement="bottom-start"
                    sx={{ cursor: 'default' }}
                  >
                    <SavingsRoundedIcon
                      sx={{ ml: 1, mr: 1, mb: -1, color: theme.palette.chips.red.color }}
                    />
                    <Typography color={theme.palette.chips.red.color} variant="button">
                      {creditBalance}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={7}>
                  <Typography>Price:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ display: 'flex', justifyContent: 'right' }}>
                    {formatPrice(price)}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>Est. Shipping:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ display: 'flex', justifyContent: 'right' }}>
                    {formatPrice(shippingCost)}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Tooltip
                    title={
                      <Box>{`Additional processing fee for using IPSecure test buy services`}</Box>
                    }
                  >
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Typography>Processing Fee:&nbsp;</Typography>
                      <InfoIcon sx={{ width: '13px', height: '13px' }} />
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ display: 'flex', justifyContent: 'right' }}>
                    {formatPrice(fee)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ display: 'flex', justifyContent: 'right', fontWeight: 700 }}>
                    Estimated Total:&nbsp;
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ display: 'flex', justifyContent: 'right', fontWeight: 700 }}>
                    {formatPrice(subtotal)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ display: 'flex', justifyContent: 'right', fontWeight: 700 }}>
                    + state and local tax
                  </Typography>
                </Grid>
              </Grid>
            )}
            {hasEnoughCredits && (
              <Grid container>
                <Grid item xs={7}>
                  <Typography>Price:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ display: 'flex', justifyContent: 'right' }}>
                    {formatPrice(price)}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>Credit Balance:</Typography>
                </Grid>
                <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Tooltip
                    title={<Box>Your test buy credit balance.</Box>}
                    arrow
                    placement="bottom-start"
                    sx={{ cursor: 'default' }}
                  >
                    <SavingsRoundedIcon sx={{ ml: 1, mr: 1, mb: -1 }} />
                    <Typography variant="button">{creditBalance}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontWeight: 700 }}>Est. Credits Due:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: 700, display: 'flex', justifyContent: 'right' }}>
                    {creditCost}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={{ display: 'flex', justifyContent: 'right' }}>
                    Est. Remaining Balance:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ display: 'flex', justifyContent: 'right' }}>
                    {creditBalance - creditCost}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProductInfo;
