import React from 'react';
import { ReactComponent as CheckboxUnchecked } from 'assets/checkbox-not-active.svg';
import { ReactComponent as IndeterminateIcon } from 'assets/checkbox-indeterminate.svg';
import { ReactComponent as CheckboxChecked } from 'assets/checkbox-active.svg';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';

const colors = {
  purple: '#881CE0',
  purpleLight: '#BE9ADB',
  purpleExtraLight: '#CC8DFF',
  lightRed: '#FF6737',
  lightRedDarker: '#cf3100',
  red: '#FE4646',
  redLighter: '#FE7474',
  redDarker: '#F10101',
  green: '#02B80A',
  black: '#1C1221',
  darkGrey: '#252C32',
  silver: '#5B6871',
  grey: '#8E989B',
  lightGrey: '#DDE2E4',
  lighterGrey: '#EDEFF3',
  backgroundGrey: '#F7F7FA',
  white: '#FFFFFF',
  linkBlue: '#0071DA',
  orange: '#FF7A00',
  chips: {
    blue: '#0071DA',
    red: '#FE4646',
    yellow: '#F7C66B',
    orange: '#FF7A00',
    lightOrange: '#FE46461A',
    purple: '#881CE0',
    pink: '#FF00C7',
    turquoise: '#53D1D2',
    burntOrange: '#F17A62',
    silver: '#5B6871',
  },
};

const fontFamily = `"Mulish", "Helvetica", "Arial", sans-serif`;
const loginFontFamily = `"CircularStd", "Mulish", "Helvetica", "Arial", sans-serif`;
const largeBorderRadius = '10px';
const smallBorderRadius = '6px';

const themeOptions = {
  borderRadius: smallBorderRadius,
  largeBorderRadius,
  spacing: 7,
  contentPaddingSpacing: 4,
  customDialogPadding: '24px 30px',
  shape: {
    borderRadius: 6,
  },
  colors: {
    ...colors,
  },
  custom: {
    login: {
      fontFamily: loginFontFamily,
      title: {
        fontFamily: loginFontFamily,
        fontSize: '50px',
        fontWeight: '700',
        lineHeight: '60px',
        marginBottom: '20px',
      },
      subtitle: {
        fontFamily: loginFontFamily,
        fontSize: '22px',
        fontWeight: '400',
        lineHeight: '37px',
        marginBottom: '30px',
      },
      buttonContained: {
        fontFamily: loginFontFamily,
        borderRadius: '12px',
        padding: '12px',
        fontSize: '16px',
        fontWeight: '500',
        color: colors.white,
        backgroundColor: colors.black,
        '&:hover': {
          backgroundColor: colors.darkGrey,
          color: colors.white,
          borderColor: colors.black,
        },
        '&.MuiButton-contained': {
          '&.Mui-disabled': {
            backgroundColor: colors.black,
            color: 'white',
          },
        },
      },
      buttonOutlined: {
        fontFamily: loginFontFamily,
        borderRadius: '12px',
        padding: '12px',
        fontSize: '16px',
        fontWeight: '500',
        backgroundColor: colors.white,
        color: colors.black,
        '&:hover': {
          backgroundColor: colors.lighterGrey,
          borderColor: colors.black,
        },
        '&.MuiButton-contained': {
          '&.Mui-disabled': {
            backgroundColor: colors.white,
            color: colors.grey,
          },
        },
        borderColor: colors.black,
      },
      switch: {
        fontFamily: loginFontFamily,
        fontSize: '16px',
        fontWeight: '500',
        color: colors.silver,
      },
      switchLink: {
        fontFamily: loginFontFamily,
        fontSize: '16px',
        fontWeight: '500',
      },
      error: {
        fontFamily: loginFontFamily,
        fontSize: '16px',
        color: colors.red,
        fontWeight: '500',
      },
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
        size: 'medium',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        label: {
          fontWeight: '600',
        },
        containedSizeLarge: {
          minHeight: '40px',
          fontSize: '14px',
          padding: '6px 16px',
        },
        contained: {
          '&.Mui-disabled': {
            backgroundColor: colors.purpleLight,
            color: 'white',
          },
          '&:hover': {
            transition: 'b ackground-color .25s',
            boxShadow: 'none',
          },
          '&:focus': {
            transition: 'background-color .25s',
            boxShadow: 'none',
          },
          '& svg': {
            fontSize: '20px',
          },
          minHeight: '36px',
          fontSize: '14px',
          fontWeight: '600',
          boxShadow: 'none',
          borderWidth: '1px',
          padding: '5px 14px',
        },
        containedSizeSmall: {
          '& svg': {
            fontSize: '18px',
          },
          minHeight: '28px',
          fontSize: '12px',
          padding: '4px 9px',
        },
        outlinedPrimary: {
          color: colors.black,
        },
        outlinedSizeLarge: {
          '& svg': {
            fontSize: '22px',
          },
          minHeight: '40px',
          fontSize: '14px',
          padding: '6px 16px',
        },
        outlined: {
          '&.Mui-disabled': {
            backgroundColor: colors.white,
            color: colors.grey,
          },
          '&:hover': {
            transition: 'background-color .25s',
            boxShadow: 'none',
          },
          '&:focus': {
            transition: 'background-color .25s',
            boxShadow: 'none',
          },
          minHeight: '36px',
          fontSize: '14px',
          boxShadow: 'none',
          borderWidth: '1px',
          padding: '5px 16px',
        },
        outlinedSizeSmall: {
          minHeight: '28px',
          fontSize: '12px',
          padding: '4px 16px',
        },
        startIcon: {
          marginRight: '6px',
        },
        endIcon: {
          marginLeft: '6px',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        formControl: {
          'label + &': {
            marginTop: '24px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: smallBorderRadius,
          border: `1px solid ${colors.lightGrey}`,
          padding: '2px 14px 2px 14px',
          backgroundColor: colors.white,
          '&.Mui-error': {
            borderColor: colors.lightRed,
            '&.Mui-focused': {
              borderColor: colors.lightRedDarker,
            },
          },
          '&.Mui-focused': {
            borderColor: colors.grey,
          },
        },
        input: {
          padding: '6px 0 6px',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
      },
      styleOverrides: {
        root: {
          minHeight: 0,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: CaretDown,
      },
      styleOverrides: {
        root: {
          '&$selected': {
            '&:focus': {
            backgroundColor: colors.white,
            border: 'none',
            },
          },
          '&:focus': {
            backgroundColor: colors.white,
            border: 'none',
          },
        },
        select: {
          '&:focus': {
            backgroundColor: colors.white,
            border: 'none',
          },
        },
        icon: {
          top: 'calc(50% - 0.85em)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        disableClearable: true,
        popupIcon: <CaretDown />,
        select: {
          disableUnderline: true,
        },
      },
      styleOverrides: {
        padding: 0,
        root: {
          borderRadius: smallBorderRadius,
          border: `1px solid ${colors.blue}`,
          padding: 0,
        },
        inputRoot: {
          '&.MuiInput-root': {
            paddingTop: '4px',
            paddingBottom: '4px',
          },
          '&.MuiOutlinedInput-root': {
            paddingTop: '0.5px',
            paddingBottom: '0.5px',
          },
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '12px',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          border: 'none',
          backgroundColor: colors.lighterGrey,
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '400',
          '&:hover': {
            backgroundColor: colors.lighterGrey,
          },
          '&:focus': {
            backgroundColor: colors.lighterGrey,
          },
          '&.Mui-focused': {
            backgroundColor: colors.lighterGrey,
          },
          '&.Mui-disabled': {
            backgroundColor: colors.lightGrey,
          },
        },
        input: {
          padding: '10px 0',
          '&:-webkit-autofill': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '400',
            fontFamily,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: '2px',
          fontSize: '12px',
          color: colors.darkGrey,
          '&.Mui-error': {
            color: colors.red,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          'label + &': {
            marginTop: '24px',
          },
          padding: '2px 14px 2px 14px',
        },
        input: {
          padding: '6px 0 6px',
          margin: 0,
        },
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxUnchecked />,
        checkedIcon: <CheckboxChecked />,
        indeterminateIcon: <IndeterminateIcon />,

      },
      styleOverrides: {
        root: {
          padding: '9px 9px 9px 11px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          fontSize: '16px',
          lineHeight: '16px',
          fontWeight: '600',
          color: colors.black,
          marginBottom: '6px',
          transform: 'translate(0, 0) scale(1);',
        },
        root: {
          overflow: 'inherit',
          fontSize: '20px',
          lineHeight: '20px',
          fontWeight: '400',
          color: colors.black,
          '&.Mui-error': {
            color: colors.black,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          color: colors.black,
          backgroundColor: `${colors.chips.silver}1A`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          minWidth: '33%',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '28px 30px 30px 30px',
        },
        spacing: {
          '& > :not(:first-of-type)': {
            marginLeft: '15px',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '25px 30px 20px 30px',
          fontSize: '18px',
          lineHeight: '24px',
          fontWeight: '600',
          fontFamily,
          borderBottom: `1px solid ${colors.lightGrey}`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily,
          backgroundColor: colors.white,
          color: colors.darkGrey,
          boxShadow: 'rgba(0, 0, 0, 0.25) 0 2px 10px',
          fontSize: '12px',
          border: '1px solid #dadde9',
        },
        arrow: {
          fontSize: 16,
          color: colors.white,
          '&::before': {
            border: '1px solid #dadde9',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0 2px 3px',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': [fontFamily],
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: colors.white,
          },
          '&.Mui-selected': {
            backgroundColor: colors.white,
            '&:hover': {
              transition: 'b ackground-color .25s',
              backgroundColor: colors.lightGrey,
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.white,
          borderRadius: '6px',
          boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.18)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: colors.darkGrey,
          '& svg ': {
            marginRight: '7px',
            color: colors.darkGrey,
            fontSize: '18px',
          },
          '&.Mui-selected': {
            backgroundColor: colors.white,
            '&:hover': {
              transition: 'b ackground-color .25s',
              backgroundColor: colors.backgroundGrey,
            },
          },
          '&:hover': {
            transition: 'b ackground-color .25s',
            backgroundColor: colors.backgroundGrey,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: colors.lighterGrey,
          padding: '8px 16px 8px 16px',
          color: colors.silver,
          fontWeight: '400',
        },
        body: {
          fontWeight: '400',
          color: colors.darkGrey,
          padding: '8px 16px 8px 16px',
        },
        sizeSmall: {
          padding: '8px 12px 8px 12px',
          // '&:last-child': {
          //   paddingRight: '12px',
          // },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.lightGrey}`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: '700',
          minWidth: 0,
          padding: 0,
          marginRight: '16px',
          marginBottom: '5px',
          minHeight: 0,
          '@media (min-width: 0px)': {
            minWidth: 0,
            minHeight: 0,
          },
          '&.Mui-selected': {
            color: colors.purple,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.purple,
      light: colors.purpleLight,
      extraLight: `${colors.purpleExtraLight}1A`,
    },
    secondary: {
      main: colors.lightRed,
    },
    error: {
      main: colors.red,
      dark: colors.redDarker,
      light: colors.redLighter,
    },
    success: {
      main: colors.green,
    },
    chips: {
      purple: {
        color: colors.chips.purple,
        backgroundColor: `${colors.chips.purple}1A`,
      },
      green: {
        color: colors.green,
        backgroundColor: `${colors.green}1A`,
      },
      blue: {
        color: colors.chips.blue,
        backgroundColor: `${colors.chips.blue}1A`,
      },
      red: {
        color: colors.chips.red,
        backgroundColor: `${colors.chips.red}1A`,
      },
      yellow: {
        color: colors.chips.yellow,
        backgroundColor: `${colors.chips.yellow}1A`,
      },
      orange: {
        color: colors.chips.orange,
        backgroundColor: `${colors.chips.orange}1A`,
      },
      pink: {
        color: colors.chips.pink,
        backgroundColor: `${colors.chips.pink}1A`,
      },
      turquoise: {
        color: colors.chips.turquoise,
        backgroundColor: `${colors.chips.turquoise}1A`,
      },
      burntOrange: {
        color: colors.chips.burntOrange,
        backgroundColor: `${colors.chips.burntOrange}1A`,
      },
      silver: {
        color: colors.chips.silver,
        backgroundColor: `${colors.chips.silver}1A`,
      },
    },
    greys: {
      black: colors.black,
      darkGrey: colors.darkGrey,
      silver: colors.silver,
      grey: colors.grey,
      lightGrey: colors.lightGrey,
      lighterGrey: colors.lighterGrey,
      backgroundGrey: colors.backgroundGrey,
      white: colors.white,
    },
  },
  typography: {
    fontFamily,
    textTransform: 'none',
    body1: {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: '400',
    },
    subtitle1: {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: '400',
    },
    h1: {
      fontSize: '24px',
      lineHeight: '30px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 'bold',
      color: colors.black,
    },
    h3: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '600',
    },
    h4: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '600',
    },
    h5: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  },
};

export default themeOptions;
