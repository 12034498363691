import React, { useContext } from 'react';
import { TableRow, TableCell, Box, Typography, Checkbox, useTheme, Link } from '@mui/material';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import { RegionDataContext } from 'contexts';
import OfferIcons from 'pages/Common/OfferIcons';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';

const LostBBOfferRow = ({ compact, offer, seller, onActionTaken, changeChecked }) => {
  const theme = useTheme();
  const { regionIso, regionCurrency, productLink } = useContext(RegionDataContext);

  return (
    <>
      <TableRow>
        <TableCell sx={{ pr: 0, border: 'none', pl: '12px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
            <Checkbox
              onChange={() => {
                if (changeChecked) {
                  changeChecked(offer.asin);
                }
              }}
              data-cy="asin_checkbox"
              checked={offer.selected ?? false}
            />
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', gap: 1, width: '100%', alignItems: 'center' }}>
            {offer.imageUrl && (
              <Box
                component="img"
                src={`${offer.imageUrl}`}
                alt="product"
                sx={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
              />
            )}
            {!offer.imageUrl && <BlankImage style={{ width: '40px', height: '40px' }} />}
            <Box
              sx={{
                overflow: 'hidden',
                display: 'block',
                fontSize: '14px',
                lineHeight: '18px',
                maxHeight: '36px',
              }}
            >
              {offer.title}
            </Box>
            <OfferIcons offer={offer} />
          </Box>
        </TableCell>
        {!compact && (
          <TableCell>
            <Link
              sx={{
                display: 'block',
                fontWeight: '500',
                fontSize: '14px',
                color: '#0071DA',
                textDecoration: 'underline',
                lineHeight: '18px',
                maxHeight: '36px',
                overflow: 'hidden',
              }}
              href={`${productLink}${offer.asin}`}
              target="_blank"
              underline="hover"
            >
              {offer.asin}
            </Link>
            {/* {offer.asin} */}
          </TableCell>
        )}
        <TableCell>
          <Typography sx={{ color: theme.palette.chips.red.color }}>
            {offer.averagePrice
              ? `${Intl.NumberFormat(regionIso, {
                  style: 'currency',
                  currency: regionCurrency,
                }).format(offer.averagePrice.toFixed(2))}`
              : '-'}
          </Typography>
        </TableCell>
        <TableCell>
          {offer.mapPrice
            ? Intl.NumberFormat(regionIso, {
                style: 'currency',
                currency: regionCurrency,
              }).format(offer.mapPrice.toFixed(2))
            : '-'}
        </TableCell>
        {!compact && (
          <>
            <TableCell>{offer.averageStock ? offer.averageStock.toFixed(0) : '-'}</TableCell>
            <TableCell>
              {offer.buyBoxWinPercentage ? offer.buyBoxWinPercentage.toFixed(2) : '-'}%
            </TableCell>
          </>
        )}
        <TableCell>
          <Typography sx={{ color: theme.palette.chips.red.color }}>
            {offer.lostSales
              ? `${Intl.NumberFormat(regionIso, {
                  style: 'currency',
                  currency: regionCurrency,
                }).format(offer.lostSales.toFixed(2))}`
              : '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <SellerAndOfferMenu
            offers={[offer]}
            seller={seller}
            sellerId={seller?.sellerId}
            onActionTaken={onActionTaken}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default LostBBOfferRow;
